import { collection, query, where } from 'firebase/firestore';
import type { Token } from '@tn/shared';

export const useTokensStore = defineStore('tokens', () => {
  const db = useFirestore();
  const user = useCurrentUser();
  const now = useNow();
  const cyclesStore = useCyclesStore();

  const tokensRef = computed(() => {
    if (!user.value) return null;

    return query(collection(db, 'tokens'), where('uid', '==', user.value.uid));
  });

  const tokens = useCollection<Token>(tokensRef, {
    ssrKey: 'user-tokens',
  });

  const isLoading = computed(() => tokens.pending.value);

  const validTokens = computed(() => {
    const currentCycleStartTimeMs = cyclesStore.currentCycle?.startDate || 0;
    const currentCycleEndTimeMs = cyclesStore.currentCycle?.endDate || 0;

    return tokens.value.filter(
      (token) =>
        token.createdAtTimeMs >= currentCycleStartTimeMs &&
        token.createdAtTimeMs < currentCycleEndTimeMs &&
        !token.used &&
        token.expiresAtTimeMs > now.value
    );
  });

  return { tokens, validTokens, isLoading };
});
